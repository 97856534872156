<template>
  <div class="container__pharmacy mt-4 ml-4">
    <section v-if="status === 'selectCountry'">
      <SelectCountry @next="handleNext" />
    </section>
    <section v-else-if="status === 'AdminCards'">
      <AdminCards
        @handleBack="status = 'selectCountry'"
        :country="countrySelect"
        :configRol="configRol"
      />
    </section>
  </div>
</template>
   
    
<script>
import { AlertForm, ICON } from '../../helpers/Alert';
import SelectCountry from "./PharmacyComponent/SelectCountry";
import AdminCards from "./Topcards/AdminCards.vue";

export default {
  name: "TopCards",
  components: { SelectCountry, AdminCards },
  data: () => ({
    status: "selectCountry", //default selectCountry
    countrySelect: "",
    configRol: {}
  }),
  created() {
    this.configRol = this.$store.state.configRol;
    if(this.configRol.administrador?.pestana.topCards.permiso === false) {
      this.$router.push({ name: "Welcome" });
      AlertForm(`${this.$i18n.t("permissions.unauthorized")} ${this.$i18n.t("admin.top.title")}` , this.$i18n.t("permissions.validateAdmin"), ICON.WARNING, "center", null);
    } 
  },
  methods: {
    handleNext(e) {
      this.status = "AdminCards";
      this.countrySelect = e;
    },
  },
};
</script>

<style scoped>
</style>