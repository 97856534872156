<template>
  <b-col class="country-group mt-5">
    <h5 class="text-left">{{ $t("welcome.country") }}</h5>
    <b-row>
      <button
        class="card-country"
        :class="{ active: isActive }"
        @click="country('MX')"
      >
        <img src="@/assets/images/mexico-flag.svg" />
        <p>Mexico</p>
      </button>
      <button
        class="card-country"
        :class="{ active: !isActive }"
        @click="country('US')"
      >
        <img src="@/assets/images/eeuu-flag.svg" />
        <p>{{ $t("welcome.usa") }}</p>
      </button>
    </b-row>
    <b-row class="justify-content-end">
      <b-button
        class="btn btn-blue-dark ml-3"
        @click="$emit('next', countrySelect)"
      >
        {{ $t("admin.pharmacy.next") }}
        <b-icon icon="arrow-right" class="icon" aria-hidden="true"></b-icon>
      </b-button>
    </b-row>
  </b-col>
</template>
<script>
export default {
  name: "SelectCountry",
  components: {},
  data: () => ({
    isActive: false, //false activa US
    countrySelect: "US",
  }),

  mounted() {
    this.isActive = this.countrySelect === "US" ? false : true;
  },
  methods: {
    country(country) {
      this.isActive = country === "US" ? false : true;
      this.countrySelect = country;
    },
    cancel() {
      console.log("cancel");
    },
    next() {
      console.log("next");
    },
  },
};
</script>

<style>
@import "../../../assets/css/global.css";

/**card country */

/**Inherence global */
.card-country {
  padding: 15px;
  margin: 10px;
  width: 100px;
  height: 120px;
}
</style>


