var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container__pharmacy mt-4 ml-4" }, [
    _vm.status === "selectCountry"
      ? _c(
          "section",
          [_c("SelectCountry", { on: { next: _vm.handleNext } })],
          1
        )
      : _vm.status === "AdminCards"
      ? _c(
          "section",
          [
            _c("AdminCards", {
              attrs: { country: _vm.countrySelect, configRol: _vm.configRol },
              on: {
                handleBack: function($event) {
                  _vm.status = "selectCountry"
                }
              }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }