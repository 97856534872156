<template>
  <div role="group" class="mt-5">
    <b-row class="mb-4">
      <b-col offset="8" cols="4">
        <b-dropdown
          id="dropdown-top-cards"
          menu-class="form-user"
          right
          variant="link"
          toggle-class="text-decoration-none"
          no-caret
          ref="dropdown-top-cards"
          class="m-2"
          v-if="validationDropdown"
        >
          <template #button-content>
            <b-button
              variant="outline-primary"
              class="button btn-blue-dark ml-0"
            >
              <span v-if="selectedView.length"
                >{{ $t("admin.top.editCard") }} +</span
              >
              <span v-else>{{ $t("admin.top.createCard") }} +</span>
            </b-button>
          </template>
          <b-dropdown-text class="my-3 text-header-user">
            <b-icon
              icon="card-checklist"
              class="mr-3"
              aria-hidden="true"
            ></b-icon>
            <span v-if="selectedView.length">
              {{ $t("admin.top.editCard") }}</span
            >
            <span v-else>{{ $t("admin.top.createCard") }}</span>
          </b-dropdown-text>
          <b-dropdown-form>
            <b-form-input
              class="form-input-style"
              size="sm"
              :placeholder="this.$i18n.t('admin.top.nameView')"
              :state="validationNameView"
              v-model="form.nombre"
            ></b-form-input>
            <div @click="(e) => e.stopPropagation()">
              <multi-select 
                :options="optionsSede"
                :selected-options="initialAppointmentBranch"
                :placeholder="this.$i18n.t('admin.role.selectBranch')"
                @select="onSelect"
                class="mb-2"
                >
              </multi-select>
            </div>
            
            <b-form-checkbox
              id="checkbox-1"
              v-model="allBranch"
              name="checkbox-1"
              class="mb-3"
            >
              {{ $t("admin.top.allBranch") }}
            </b-form-checkbox>
            <b-form-select
              v-model="categorySelected"
              :options="optionsCategory"
              size="sm"
              class="mb-3"
              :disabled="selectedView.length > 0"
            >
              <template #first>
                <b-form-select-option :value="null" disabled
                  >-- {{ $t("admin.top.category") }} --</b-form-select-option
                >
              </template>
            </b-form-select>
            <b-form-select
              v-model="form.rol"
              :options="optionsRol"
              size="sm"
              class="mb-3"
              :disabled="selectedView.length > 0"
            >
              <template #first>
                <b-form-select-option :value="null" disabled
                  >-- {{ $t("admin.top.rol") }} --</b-form-select-option
                >
              </template>
            </b-form-select>
            <div class="search-product">
              <div class="groupSearch mb-3">
                <p>
                  <b>{{ $t("admin.top.optionTop") }}</b>
                </p>
                <b-input-group size="sm" class="inputSearch">
                  <b-form-input
                    id="filter-input"
                    v-model="filterOptions"
                    type="search"
                    :placeholder="this.$i18n.t('admin.top.search')"
                    class="custom-input"
                  >
                  </b-form-input>
                </b-input-group>
              </div>
              <b-dropdown-divider />
              <p v-if="!allOptions.length">
                {{ $t("admin.top.selectCategory") }}
              </p>
              <div
                v-for="(item, index) in searchFilter"
                :key="index"
                class="item-product"
              >
                <div>
                  <b>{{ item.split("|")[0] }}: </b>{{ item.split("|")[1] }}
                </div>
                <input
                  type="number"
                  max="10"
                  min="1"
                  :class="{
                    invalid:
                      numberItems[item.split('|')[0]] > 10 ||
                      numberItems[item.split('|')[0]] < 1,
                    warning: numberItems[item.split('|')[0]] === '',
                  }"
                  v-model="numberItems[item.split('|')[0]]"
                  @change="handleObject"
                />
              </div>
            </div>
            <b-col class="justify-content-end buttons-dropdown">
              <b-button class="btn btn-blue-dark-outline ml-0" @click="cancel">
                {{ $t("admin.users.cancel") }}
              </b-button>
              <b-button
                class="btn btn-blue-dark ml-0"
                :disabled="isLoading || !validationNameView"
                v-if="selectedView.length"
                @click="handleEdit"
              >
                {{ $t("admin.users.save") }}
                <b-icon
                  icon="arrow-right"
                  class="icon"
                  aria-hidden="true"
                ></b-icon>
              </b-button>
              <b-button
                class="btn btn-blue-dark ml-0"
                :disabled="isLoading || !validationNameView"
                @click="handleCreate"
                v-else
              >
                {{ $t("admin.users.create") }}
                <b-icon
                  icon="arrow-right"
                  class="icon"
                  aria-hidden="true"
                ></b-icon>
              </b-button>
            </b-col>
          </b-dropdown-form>
        </b-dropdown>
      </b-col>
    </b-row>
    <!-- TABLE USERS-->
    <div v-if="configRol.administrador.pestana.topCards.acciones.listar">
      <b-table
        :items="itemsCards"
        :fields="fieldsUsers"
        stacked="md"
        show-empty
        hover
        table-variant="light"
        class="tableFirst"
        sort-icon-left
        select-mode="single"
        selectable
        borderless
        thead-tr-class="table-cards"
        tbody-tr-class="table-cards"
        responsive="lg"
        @row-selected="onRowSelectedUser"
        :busy="isLoading"
      >
        <template #cell(actions)="row">
          <div
            @click="handleDelete(row)"
            class="delete-item"
            v-if="configRol.administrador.pestana.topCards.acciones.eliminar"
          >
            <b-icon icon="trash" variant="danger" aria-hidden="true"></b-icon>
          </div>
        </template>
        <template #cell(nombre)="row">
          <b>{{row.value}}</b>
        </template>
        <template #cell(sede_origen)="row">
          {{row.value.join(", ")}}
        </template>
        <template #cell(rol)="row">
          {{nameRol(row.item.rol)}}
        </template>
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
      </b-table>
    </div>
    <div v-else>
      <b-alert show dismissible>
        <p>
         {{ $t("permissions.msgPermissionsTop") }}
        </p>
      </b-alert>
    </div>

    <b-row>
      <b-button
        variant="outline-primary"
        class="button btn-blue-dark-outline ml-0"
        @click="$emit('handleBack')"
      >
        {{ $t("admin.pharmacy.Back") }}
      </b-button>
    </b-row>
  </div>
</template>

<script>
import { API_HUBSPOT, API_ROOT } from "../../../Constants/Api";
import { AlertConfirm, AlertForm, ICON } from "../../../helpers/Alert";
import { MultiSelect   } from 'vue-search-select';
import 'vue-search-select/dist/VueSearchSelect.css';
import { ApiRequest } from '../../../helpers/ApiRequest';

export default {
  name: "AdminCards",
  components: { MultiSelect },
  props: ["country", "configRol"],
  data: () => ({
    itemsCards: [],
    form: {
      nombre: "",
      sede_origen: null,
      categoria: null,
      rol: null,
      farmacia: "N/A",
      localidad: "",
      productos_top: [],
    },
    initialAppointmentBranch: [],
    allBranch: false,
    idCard: "",
    categorySelected: null,
    numberItems: {},
    selectedView: [],
    isLoading: false,
    isEdit: false,

    filterOptions: "",
    allOptions: [],
    searchFilter: [],
    optionsRol: [],
    optionsCategory: [],

    // origen
    optionsSede: [],
    allProducts: {},
    optionsPharmacy: [],
  }),

  async mounted() {
    try {
      this.isLoading = true;
      await this.getProductsXCountry();
      await this.getViewCards();
      await this.getSucursales();
      await this.getRoles();
    } catch (error) {
      console.log(error);
    }
  },

  computed: {
    validationDropdown() {
      if (
        this.configRol.administrador.pestana.topCards.acciones.crear &&
        this.selectedView.length === 0
      ) {
        return true;
      } else if (
        this.configRol.administrador.pestana.topCards.acciones.editar &&
        this.selectedView.length
      ) {
        return true;
      }
      return false;
    },
    validationNameView() {
      return this.form.nombre.length > 3;
    },

    fieldsUsers() {
      return [
        {
          key: "nombre",
          label: this.$i18n.t("admin.top.name"),
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "rol",
          label: this.$i18n.t("admin.role.role"),
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "categoria",
          label: this.$i18n.t("admin.bussiness.category"),
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "sede_origen",
          label: this.$i18n.t("admin.top.branch"),
          sortable: true,
          sortDirection: "desc",
        },

        { key: "actions", label: "" },
      ];
    },
  },

  watch: {
    filterOptions() {
      this.searchFilter = this.allOptions.filter((el) =>
        el.toLowerCase().includes(this.filterOptions.toLowerCase())
      );
    },
    categorySelected() {
      if (this.categorySelected) {
        this.form.categoria = this.categorySelected;
        let allOptions = this.allProducts[this.categorySelected];
        let filterTopCards = [];
        Object.keys(this.numberItems).forEach((el) => {
          let number = this.numberItems[el];
          filterTopCards[number] = allOptions.find(option => option.split("|")[0] === el);
          allOptions = allOptions.filter(option => option.split("|")[0] !== el);
        })
        filterTopCards.shift();
        filterTopCards = filterTopCards.filter(el => el);
        let mergeArray = [...filterTopCards, ...allOptions ];
        this.allOptions = mergeArray;
        this.searchFilter = this.allOptions;
      } else {
        this.allOptions = [];
        this.searchFilter = [];
      }
    },
    
    numberItems() {
      let validate = Object.values(this.numberItems).every(el => el !== "");
      if(!validate) {
        let newObj = {};
        for (const key in this.numberItems) {
          if(this.numberItems[key])
            newObj[key] = this.numberItems[key];
        }
        this.numberItems = newObj;
      }
    },
    allBranch() {
      if(this.allBranch) {
        this.initialAppointmentBranch = this.optionsSede;
      } else {
        this.initialAppointmentBranch = [];
      }
    }
  },
  methods: {
    handleObject () {
      this.numberItems =  Object.assign({}, this.numberItems);
      
    },
    async getViewCards() {
      try {
        this.isLoading = true;
        const cards = await ApiRequest.post(
          `${API_ROOT}cards/listarCardsAdm`,
          {
            localidad: this.country === "US" ? 2 : 1,
          }
        );
        this.itemsCards = cards.configCards;
      } catch (err) {
        this.itemsCards = [];
      } finally {
        this.isLoading = false;
      }
    },
    async getSucursales() {
      try {
        if (this.country === "US") {
          let us = await ApiRequest.get(`${API_HUBSPOT}sucursalesUS`);
          this.optionsSede = us.sucursales.map((el) => ({
            text: el,
            value: el,
          }));
        } else {
          let mx = await ApiRequest.get(`${API_HUBSPOT}sucursalesMX`);
          this.optionsSede = mx.sucursales.map((el) => ({
            text: el,
            value: el,
          }));
        }
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`, this.$i18n.t("admin.top.errorBranch"), ICON.ERROR);
      }
    },
    async getRoles() {
      try {
        const data = await ApiRequest.get(`${API_ROOT}roles`);
        let rolesOptions = data.lista.map((el) => ({
          text: el.name,
          value: el["_id"],
        }));
        this.optionsRol = rolesOptions;
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`, this.$i18n.t("admin.top.errorRol"), ICON.ERROR);
      }
    },

    async getProductsXCountry() {
      try {
        const data = await ApiRequest.post(
          `${API_ROOT}cards/productosCategoriaPais`,
          {
            localidad: this.country === "US" ? 2 : 1,
          }
        );
        this.allProducts = data.categorias_productos;
        let optionCategory = Object.keys(this.allProducts);
        this.optionsCategory = optionCategory.filter(
          (el) =>
            !["COVERAGE", "PAQUETE MEDICAMENTO"].includes(el)
        );
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`,this.$i18n.t("admin.top.errorProducts"),ICON.ERROR);
      }
    },
    cancel() {
      this.$refs["dropdown-top-cards"].hide(true);
    },

    async handleCreate() {
      try {
        // validar que no hayan campos repetidos
        let listNumbers = Object.values(this.numberItems).filter(
          (el) => el !== ""
        );
        this.form.sede_origen = this.initialAppointmentBranch.map(el => el.value);
        if (this.form.farmacia === "" && this.categorySelected === "FUNGIBLES - MEDICAMENTOS")
          return AlertForm(this.$i18n.t("admin.top.optionTop"),
            this.$i18n.t("admin.top.notSelectPharmacy"),
            ICON.WARNING, "center", 4000);
        if (!this.form.sede_origen.length)
          return AlertForm(this.$i18n.t("admin.top.notSelectInitialAppointment"),
            "", ICON.WARNING, "center", 4000);
        if (!this.form.categoria)
          return AlertForm(this.$i18n.t("admin.top.notSelectCategory"),
            "", ICON.WARNING, "center", 4000);
        if (this.form.rol === null)
          return AlertForm(this.$i18n.t("admin.top.notSelectRol"),
            "", ICON.WARNING, "center", 4000);

        if (!listNumbers.length)
          return AlertForm(this.$i18n.t("admin.top.optionTopWarning"),
            "", ICON.WARNING, "center", 4000);
        if (listNumbers.length !== new Set(listNumbers).size)
          return AlertForm(this.$i18n.t("admin.top.optionTop"),
            this.$i18n.t("admin.top.repeatFiels"),
            ICON.WARNING, "center", 4000);
        // validar que no hayan valores mayor a 10
        if (Math.max(...listNumbers) > 10)
          return AlertForm(this.$i18n.t("admin.top.optionTop"),
            this.$i18n.t("admin.top.greater"),
            ICON.WARNING, "center", 4000);

        if (Math.min(...listNumbers) < 1)
          return AlertForm(this.$i18n.t("admin.top.optionTop"),
            this.$i18n.t("admin.top.less"),
            ICON.WARNING, "center", 4000);

        const sortTop = this.sortOptionsSelected();
        
        this.form.productos_top = sortTop;
        this.form.localidad = this.country === "US" ? 2 : 1;

        await ApiRequest.post(`${API_ROOT}cards`, this.form);
        
        AlertForm(
          this.$i18n.t("admin.top.success"),
          this.$i18n.t("admin.top.createView"),
          ICON.SUCCESS
        );
        this.form.nombre = "";
        this.form.sede_origen = null;
        this.form.categoria = null;
        this.categorySelected = null;
        this.form.rol = null;
        this.form.localidad = "";
        this.numberItems = {};
        this.form.farmacia = "N/A";
        this.initialAppointmentBranch = [];
        this.$refs["dropdown-top-cards"].hide(true);

        // ACTUALIZAR LA LISTA DE LA TABLA DE CARDS
        await this.getViewCards();
      } catch (err) {
        this.form.rol = this.form.rol[0];
        if (err.status === 500 && err.data.msg === "La card a guardar ya existe.")
          return AlertForm(`Error Code: ${err.status}`, this.$i18n.t("admin.top.existCard"), ICON.WARNING);
        AlertForm(`Error Code: ${err.status}`, err.statusText, ICON.ERROR); 
      }
    },

    async handleEdit() {
      try {
        let listNumbers = Object.values(this.numberItems).filter(
          (el) => el !== ""
        );
        if (!this.form.sede_origen.length)
          return AlertForm(this.$i18n.t("admin.top.notSelectInitialAppointment"),
            "", ICON.WARNING,"center", 4000
          );
        if (listNumbers.length !== new Set(listNumbers).size) {
          return AlertForm(
            this.$i18n.t("admin.top.optionTop"),
            this.$i18n.t("admin.top.repeatFiels"),
            ICON.WARNING,
            "center",
            4000
          );
        }
        // validar que no hayan valores mayor a 10
        if (Math.max(...listNumbers) > 10) {
          return AlertForm(
            this.$i18n.t("admin.top.optionTop"),
            this.$i18n.t("admin.top.greater"),
            ICON.WARNING,
            "center",
            4000
          );
        }

        if (Math.min(...listNumbers) < 1) {
          return AlertForm(
            this.$i18n.t("admin.top.optionTop"),
            this.$i18n.t("admin.top.less"),
            ICON.WARNING,
            "center",
            4000
          );
        }
        const sortTop = this.sortOptionsSelected();
        this.form.sede_origen = this.initialAppointmentBranch.map(el => el.value);
        this.form.productos_top = sortTop;
        await ApiRequest.put(`${API_ROOT}cards`, {
          ...this.form,
          id: this.idCard,
        });
        AlertForm(
          this.$i18n.t("admin.top.success"),
          this.$i18n.t("admin.top.messageSuccessEdit"),
          ICON.SUCCESS
        );
        this.$refs["dropdown-top-cards"].hide(true);
        // ACTUALIZAR TABLA
        await this.getViewCards();
      } catch (err) {
        this.form.rol = this.form.rol[0];
        AlertForm(`Error Code: ${err.status}`, this.$i18n.t("admin.top.errorUpdateCard"), ICON.ERROR);
      }
    },

    sortOptionsSelected() {
      const optionsAllTop = [];

      for (const el of this.allOptions) {
        if (this.numberItems[el.split("|")[0]]) {
          optionsAllTop.push({
            name: el,
            position: Number(this.numberItems[el.split("|")[0]]),
          });
        }
      }
      const sortTop = [];

      for (let i = 1; i <= 10; i++) {
        for (const el of optionsAllTop) {
          if (el.position === i) {
            delete el.position;
            sortTop.push(el.name.split("|")[0]);
          }
        }
      }
      return sortTop;
    },

    async handleDelete(e) {
      AlertConfirm(
        this.$i18n.t("admin.top.delete"),
        `Name : ${e.item.nombre}`,
        ICON.WARNING,
        () => this.deleteConfirm(e.item._id)
      );
    },

    async deleteConfirm(id) {
      try {
        await ApiRequest.delete(`${API_ROOT}cards`, {id});
        AlertForm(this.$i18n.t("admin.top.messageDelete"), "", ICON.SUCCESS);
        await this.getViewCards();
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`,  this.$i18n.t("admin.top.errorDelete"), ICON.ERROR);
      }
    },
    onSelect (items) {
      setTimeout(() =>this.initialAppointmentBranch = items, 100);
      this.allBranch = items.length === this.optionsSede.length;
    },
    onRowSelectedUser(items) {
      this.categorySelected = null;
      this.selectedView = items;
      this.numberItems = {};
      if (items.length) {
        const [view] = items;
        this.form.nombre = view.nombre || "";
        this.form.sede_origen = view.sede_origen;
       
        this.form.rol = view.rol || null;
        this.form.localidad = view.localidad;
        this.form.farmacia = view.farmacia || "N/A";
        this.initialAppointmentBranch = view.sede_origen.map(el => ({value: el, text: el}));
        this.allBranch = view.sede_origen.length === this.optionsSede.length;
        let i = 1;
        for (const el of view.productos_top) {
          this.numberItems[el] = i;
          i++;
        }
        this.idCard = view._id;
        setTimeout(() => {
          this.categorySelected = view.categoria;
        }, 100)
         
      } else {
        this.form.nombre = "";
        this.form.sede_origen = null;
        this.form.categoria = null;
        this.categorySelected = null;
        this.form.rol = null;
        this.form.localidad = "";
        this.numberItems = {};
        this.form.farmacia = "N/A";
        this.initialAppointmentBranch = [];
        this.allBranch = false;
      }
    },
    nameRol(id) {
      let find = this.optionsRol.find(el => el.value === id);
      return find?.text;
    }
  },
};
</script>

<style scoped >
@import "../../../assets/css/global.css";

/** FORM USER */
.form-user {
  font-weight: bold;
}
input.form-control {
  color: #333232;
  border: none;
}
/**menu dropdown */
#dropdown-top-cards /deep/ .form-user {
  width: 400px;
}
#dropdown-top-cards /deep/ .b-dropdown-text {
  color: #707070;
  font-weight: bolder;
}
#dropdown-top-cards input.form-input-style,
#dropdown-top-cards /deep/ .select {
  font-weight: bold;
  border: none;
  border-radius: 0%;
  border-bottom: 1px solid #7070708a;
  margin: 15px 0;
}
#dropdown-top-cards /deep/ .select {
  color: #707070c4;
}
#dropdown-top-cards input.form-input-style::placeholder {
  color: #7070709d;
}
#dropdown-top-cards input.form-input-style:focus {
  outline: none;
}
/**BUTTON STYLES */
.text-align-end {
  text-align: end;
}
#dropdown-top-cards .buttons-dropdown {
  display: flex;
}
#dropdown-top-cards .btn-blue-dark,
#dropdown-top-cards .btn-blue-dark-outline {
  padding: 5px 10px;
  margin: 5px;
}
/**Table styles */
.tableFirst /deep/ .table-users {
  border-bottom: 1px solid rgb(160, 158, 158);
}

/* Option Search */
.search-product {
  border: thin solid #ced4da;
  padding: 0.7rem;
  border-radius: 0.4rem;
  margin-bottom: 1rem;
  height: 250px;
  overflow-y: auto;
}

.search-product p {
  margin-bottom: 0.5rem;
}

.item-product {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.4rem;
  font-size: 0.8rem;
}

.item-product input {
  width: 40px;
  height: 24px;
  margin-left: 1rem;
  border: none;
  border-bottom: 1px solid #333232;
}

.item-product input.invalid {
  border: 1px solid red;
}

.item-product input.warning {
  border: 1px solid rgb(212, 203, 64);
}

.delete-item:hover {
  color: red;
  text-decoration: underline;
  cursor: pointer;
}
</style>