var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-col",
    { staticClass: "country-group mt-5" },
    [
      _c("h5", { staticClass: "text-left" }, [
        _vm._v(_vm._s(_vm.$t("welcome.country")))
      ]),
      _c("b-row", [
        _c(
          "button",
          {
            staticClass: "card-country",
            class: { active: _vm.isActive },
            on: {
              click: function($event) {
                return _vm.country("MX")
              }
            }
          },
          [
            _c("img", {
              attrs: { src: require("@/assets/images/mexico-flag.svg") }
            }),
            _c("p", [_vm._v("Mexico")])
          ]
        ),
        _c(
          "button",
          {
            staticClass: "card-country",
            class: { active: !_vm.isActive },
            on: {
              click: function($event) {
                return _vm.country("US")
              }
            }
          },
          [
            _c("img", {
              attrs: { src: require("@/assets/images/eeuu-flag.svg") }
            }),
            _c("p", [_vm._v(_vm._s(_vm.$t("welcome.usa")))])
          ]
        )
      ]),
      _c(
        "b-row",
        { staticClass: "justify-content-end" },
        [
          _c(
            "b-button",
            {
              staticClass: "btn btn-blue-dark ml-3",
              on: {
                click: function($event) {
                  return _vm.$emit("next", _vm.countrySelect)
                }
              }
            },
            [
              _vm._v(" " + _vm._s(_vm.$t("admin.pharmacy.next")) + " "),
              _c("b-icon", {
                staticClass: "icon",
                attrs: { icon: "arrow-right", "aria-hidden": "true" }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }