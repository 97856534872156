var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-5", attrs: { role: "group" } },
    [
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c(
            "b-col",
            { attrs: { offset: "8", cols: "4" } },
            [
              _vm.validationDropdown
                ? _c(
                    "b-dropdown",
                    {
                      ref: "dropdown-top-cards",
                      staticClass: "m-2",
                      attrs: {
                        id: "dropdown-top-cards",
                        "menu-class": "form-user",
                        right: "",
                        variant: "link",
                        "toggle-class": "text-decoration-none",
                        "no-caret": ""
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "button-content",
                            fn: function() {
                              return [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "button btn-blue-dark ml-0",
                                    attrs: { variant: "outline-primary" }
                                  },
                                  [
                                    _vm.selectedView.length
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("admin.top.editCard")
                                            ) + " +"
                                          )
                                        ])
                                      : _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("admin.top.createCard")
                                            ) + " +"
                                          )
                                        ])
                                  ]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        948639593
                      )
                    },
                    [
                      _c(
                        "b-dropdown-text",
                        { staticClass: "my-3 text-header-user" },
                        [
                          _c("b-icon", {
                            staticClass: "mr-3",
                            attrs: {
                              icon: "card-checklist",
                              "aria-hidden": "true"
                            }
                          }),
                          _vm.selectedView.length
                            ? _c("span", [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("admin.top.editCard"))
                                )
                              ])
                            : _c("span", [
                                _vm._v(_vm._s(_vm.$t("admin.top.createCard")))
                              ])
                        ],
                        1
                      ),
                      _c(
                        "b-dropdown-form",
                        [
                          _c("b-form-input", {
                            staticClass: "form-input-style",
                            attrs: {
                              size: "sm",
                              placeholder: this.$i18n.t("admin.top.nameView"),
                              state: _vm.validationNameView
                            },
                            model: {
                              value: _vm.form.nombre,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "nombre", $$v)
                              },
                              expression: "form.nombre"
                            }
                          }),
                          _c(
                            "div",
                            {
                              on: {
                                click: function(e) {
                                  return e.stopPropagation()
                                }
                              }
                            },
                            [
                              _c("multi-select", {
                                staticClass: "mb-2",
                                attrs: {
                                  options: _vm.optionsSede,
                                  "selected-options":
                                    _vm.initialAppointmentBranch,
                                  placeholder: this.$i18n.t(
                                    "admin.role.selectBranch"
                                  )
                                },
                                on: { select: _vm.onSelect }
                              })
                            ],
                            1
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "mb-3",
                              attrs: { id: "checkbox-1", name: "checkbox-1" },
                              model: {
                                value: _vm.allBranch,
                                callback: function($$v) {
                                  _vm.allBranch = $$v
                                },
                                expression: "allBranch"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("admin.top.allBranch")) +
                                  " "
                              )
                            ]
                          ),
                          _c("b-form-select", {
                            staticClass: "mb-3",
                            attrs: {
                              options: _vm.optionsCategory,
                              size: "sm",
                              disabled: _vm.selectedView.length > 0
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "first",
                                  fn: function() {
                                    return [
                                      _c(
                                        "b-form-select-option",
                                        {
                                          attrs: { value: null, disabled: "" }
                                        },
                                        [
                                          _vm._v(
                                            "-- " +
                                              _vm._s(
                                                _vm.$t("admin.top.category")
                                              ) +
                                              " --"
                                          )
                                        ]
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              false,
                              3327928112
                            ),
                            model: {
                              value: _vm.categorySelected,
                              callback: function($$v) {
                                _vm.categorySelected = $$v
                              },
                              expression: "categorySelected"
                            }
                          }),
                          _c("b-form-select", {
                            staticClass: "mb-3",
                            attrs: {
                              options: _vm.optionsRol,
                              size: "sm",
                              disabled: _vm.selectedView.length > 0
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "first",
                                  fn: function() {
                                    return [
                                      _c(
                                        "b-form-select-option",
                                        {
                                          attrs: { value: null, disabled: "" }
                                        },
                                        [
                                          _vm._v(
                                            "-- " +
                                              _vm._s(_vm.$t("admin.top.rol")) +
                                              " --"
                                          )
                                        ]
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              false,
                              2249451857
                            ),
                            model: {
                              value: _vm.form.rol,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "rol", $$v)
                              },
                              expression: "form.rol"
                            }
                          }),
                          _c(
                            "div",
                            { staticClass: "search-product" },
                            [
                              _c(
                                "div",
                                { staticClass: "groupSearch mb-3" },
                                [
                                  _c("p", [
                                    _c("b", [
                                      _vm._v(
                                        _vm._s(_vm.$t("admin.top.optionTop"))
                                      )
                                    ])
                                  ]),
                                  _c(
                                    "b-input-group",
                                    {
                                      staticClass: "inputSearch",
                                      attrs: { size: "sm" }
                                    },
                                    [
                                      _c("b-form-input", {
                                        staticClass: "custom-input",
                                        attrs: {
                                          id: "filter-input",
                                          type: "search",
                                          placeholder: this.$i18n.t(
                                            "admin.top.search"
                                          )
                                        },
                                        model: {
                                          value: _vm.filterOptions,
                                          callback: function($$v) {
                                            _vm.filterOptions = $$v
                                          },
                                          expression: "filterOptions"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c("b-dropdown-divider"),
                              !_vm.allOptions.length
                                ? _c("p", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("admin.top.selectCategory")
                                        ) +
                                        " "
                                    )
                                  ])
                                : _vm._e(),
                              _vm._l(_vm.searchFilter, function(item, index) {
                                return _c(
                                  "div",
                                  { key: index, staticClass: "item-product" },
                                  [
                                    _c("div", [
                                      _c("b", [
                                        _vm._v(
                                          _vm._s(item.split("|")[0]) + ": "
                                        )
                                      ]),
                                      _vm._v(_vm._s(item.split("|")[1]) + " ")
                                    ]),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.numberItems[item.split("|")[0]],
                                          expression:
                                            "numberItems[item.split('|')[0]]"
                                        }
                                      ],
                                      class: {
                                        invalid:
                                          _vm.numberItems[item.split("|")[0]] >
                                            10 ||
                                          _vm.numberItems[item.split("|")[0]] <
                                            1,
                                        warning:
                                          _vm.numberItems[
                                            item.split("|")[0]
                                          ] === ""
                                      },
                                      attrs: {
                                        type: "number",
                                        max: "10",
                                        min: "1"
                                      },
                                      domProps: {
                                        value:
                                          _vm.numberItems[item.split("|")[0]]
                                      },
                                      on: {
                                        change: _vm.handleObject,
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.numberItems,
                                            item.split("|")[0],
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ]
                                )
                              })
                            ],
                            2
                          ),
                          _c(
                            "b-col",
                            {
                              staticClass:
                                "justify-content-end buttons-dropdown"
                            },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "btn btn-blue-dark-outline ml-0",
                                  on: { click: _vm.cancel }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("admin.users.cancel")) +
                                      " "
                                  )
                                ]
                              ),
                              _vm.selectedView.length
                                ? _c(
                                    "b-button",
                                    {
                                      staticClass: "btn btn-blue-dark ml-0",
                                      attrs: {
                                        disabled:
                                          _vm.isLoading ||
                                          !_vm.validationNameView
                                      },
                                      on: { click: _vm.handleEdit }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("admin.users.save")) +
                                          " "
                                      ),
                                      _c("b-icon", {
                                        staticClass: "icon",
                                        attrs: {
                                          icon: "arrow-right",
                                          "aria-hidden": "true"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _c(
                                    "b-button",
                                    {
                                      staticClass: "btn btn-blue-dark ml-0",
                                      attrs: {
                                        disabled:
                                          _vm.isLoading ||
                                          !_vm.validationNameView
                                      },
                                      on: { click: _vm.handleCreate }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("admin.users.create")) +
                                          " "
                                      ),
                                      _c("b-icon", {
                                        staticClass: "icon",
                                        attrs: {
                                          icon: "arrow-right",
                                          "aria-hidden": "true"
                                        }
                                      })
                                    ],
                                    1
                                  )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm.configRol.administrador.pestana.topCards.acciones.listar
        ? _c(
            "div",
            [
              _c("b-table", {
                staticClass: "tableFirst",
                attrs: {
                  items: _vm.itemsCards,
                  fields: _vm.fieldsUsers,
                  stacked: "md",
                  "show-empty": "",
                  hover: "",
                  "table-variant": "light",
                  "sort-icon-left": "",
                  "select-mode": "single",
                  selectable: "",
                  borderless: "",
                  "thead-tr-class": "table-cards",
                  "tbody-tr-class": "table-cards",
                  responsive: "lg",
                  busy: _vm.isLoading
                },
                on: { "row-selected": _vm.onRowSelectedUser },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "cell(actions)",
                      fn: function(row) {
                        return [
                          _vm.configRol.administrador.pestana.topCards.acciones
                            .eliminar
                            ? _c(
                                "div",
                                {
                                  staticClass: "delete-item",
                                  on: {
                                    click: function($event) {
                                      return _vm.handleDelete(row)
                                    }
                                  }
                                },
                                [
                                  _c("b-icon", {
                                    attrs: {
                                      icon: "trash",
                                      variant: "danger",
                                      "aria-hidden": "true"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      }
                    },
                    {
                      key: "cell(nombre)",
                      fn: function(row) {
                        return [_c("b", [_vm._v(_vm._s(row.value))])]
                      }
                    },
                    {
                      key: "cell(sede_origen)",
                      fn: function(row) {
                        return [
                          _vm._v(" " + _vm._s(row.value.join(", ")) + " ")
                        ]
                      }
                    },
                    {
                      key: "cell(rol)",
                      fn: function(row) {
                        return [
                          _vm._v(" " + _vm._s(_vm.nameRol(row.item.rol)) + " ")
                        ]
                      }
                    },
                    {
                      key: "table-busy",
                      fn: function() {
                        return [
                          _c(
                            "div",
                            { staticClass: "text-center text-primary my-2" },
                            [
                              _c("b-spinner", { staticClass: "align-middle" }),
                              _c("strong", [_vm._v("Loading...")])
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  288176558
                )
              })
            ],
            1
          )
        : _c(
            "div",
            [
              _c("b-alert", { attrs: { show: "", dismissible: "" } }, [
                _c("p", [
                  _vm._v(
                    " " + _vm._s(_vm.$t("permissions.msgPermissionsTop")) + " "
                  )
                ])
              ])
            ],
            1
          ),
      _c(
        "b-row",
        [
          _c(
            "b-button",
            {
              staticClass: "button btn-blue-dark-outline ml-0",
              attrs: { variant: "outline-primary" },
              on: {
                click: function($event) {
                  return _vm.$emit("handleBack")
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("admin.pharmacy.Back")) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }